<!--
 * @Author: lbh
 * @Date: 2024-01-04 17:45:22
 * @LastEditors: lbh
 * @LastEditTime: 2024-01-17 09:51:44
 * @Description: 全屏廣告 - 編輯
-->
<template>
  <div class="edit-full-screen-ad-box">
    <div v-if="type == 'fc-dialog'">
      <!-- 套餐 -->
      <div class="cell-b">
        <selfCheckPcOrPhone
          title="套餐配置"
          :defalutActive="defalutActive"
        >
          <template #pc>
            <selfCell title="套餐ID">
              <el-input
                v-model="configs_.setMealId"
                placeholder="請輸入套餐ID"
                @change="setValue('setMealId')"
              />
            </selfCell>
            <selfCell title="規格ID">
              <el-input
                v-model="configs_.purchaseSpecId"
                placeholder="請輸入規格ID"
                @change="setValue('purchaseSpecId')"
              />
            </selfCell>
          </template>
        </selfCheckPcOrPhone>
      </div>
      <!-- 背景 -->
      <div class="cell-b">
        <selfCheckPcOrPhone
          title="背景配置"
          :defalutActive="defalutActive"
        >
          <template #pc>
            <selfCell title="背景色">
              <el-color-picker
                v-model="configs_.first.bg.bgStart"
                show-alpha
                @change="setValue('first')"
              />
              <el-color-picker
                v-model="configs_.first.bg.bgEnd"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="小圓角">
              <selfSelectPX
                v-model="configs_.first.bg.minRadius"
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="大圓角">
              <selfSelectPX
                v-model="configs_.first.bg.maxRadius"
                @change="setValue('first')"
              />
            </selfCell>
          </template>
          <template #phone>
            <selfCell title="背景色">
              <el-color-picker
                v-model="configs_.first.bg.bgStartByPhone"
                show-alpha
                @change="setValue('first')"
              />
              <el-color-picker
                v-model="configs_.first.bg.bgEndByPhone"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="小圓角">
              <selfSelectPX
                v-model="configs_.first.bg.minRadiusByPhone"
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="大圓角">
              <selfSelectPX
                v-model="configs_.first.bg.maxRadiusByPhone"
                @change="setValue('first')"
              />
            </selfCell>
          </template>
        </selfCheckPcOrPhone>
      </div>
      <!-- 標題 -->
      <div class="cell-b">
        <selfCheckPcOrPhone
          title="標題配置"
          :defalutActive="defalutActive"
        >
          <template #pc>
            <selfCell
              title="文字"
              :column="true"
            >
              <el-input
                v-for="lItem in language"
                :key="lItem.code"
                v-model="configs_.first.title[`${lItem.code=='HK'?'':lItem.code}text`]"
                :placeholder="`請輸入-${lItem.name}`"
                type="textarea"
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字大小">
              <selfSelectPX
                v-model="configs_.first.title.fontSize"
                @change="setValue('first')"
              ></selfSelectPX>
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="configs_.first.title.fontColor"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>

            <selfCell title="背景色">
              <el-color-picker
                v-model="configs_.first.title.bgStart"
                show-alpha
                @change="setValue('first')"
              />
              <el-color-picker
                v-model="configs_.first.title.bgEnd"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="投影色">
              <el-color-picker
                v-model="configs_.first.title.shadowColor"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
          </template>
          <template #phone>
            <selfCell
              title="文字"
              :column="true"
            >
              <el-input
                v-for="lItem in language"
                :key="lItem.code"
                v-model="configs_.first.title[`${lItem.code=='HK'?'':lItem.code}textByPhone`]"
                :placeholder="`請輸入-${lItem.name}`"
                type="textarea"
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字大小">
              <selfSelectPX
                v-model="configs_.first.title.fontSizeByPhone"
                @change="setValue('first')"
              ></selfSelectPX>
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="configs_.first.title.fontColorByPhone"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>

            <selfCell title="背景色">
              <el-color-picker
                v-model="configs_.first.title.bgStartByPhone"
                show-alpha
                @change="setValue('first')"
              />
              <el-color-picker
                v-model="configs_.first.title.bgEndByPhone"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="投影色">
              <el-color-picker
                v-model="configs_.first.title.shadowColorByPhone"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
          </template>
        </selfCheckPcOrPhone>
      </div>
      <!-- 描述 -->
      <div class="cell-b">
        <selfCheckPcOrPhone
          title="描述配置"
          :defalutActive="defalutActive"
        >
          <template #pc>
            <selfCell
              title="文字"
              :column="true"
            >
              <el-input
                v-for="lItem in language"
                :key="lItem.code"
                v-model="configs_.first.desc[`${lItem.code=='HK'?'':lItem.code}text`]"
                :placeholder="`請輸入-${lItem.name}`"
                type="textarea"
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字大小">
              <selfSelectPX
                v-model="configs_.first.desc.fontSize"
                @change="setValue('first')"
              ></selfSelectPX>
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="configs_.first.desc.fontColor"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字行高">
              <selfSelectPX
                v-model="configs_.first.desc.fontHeight"
                @change="setValue('first')"
              ></selfSelectPX>
            </selfCell>
            <selfCell title="投影顏色">
              <el-color-picker
                v-model="configs_.first.desc.shadowColor"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
          </template>
          <template #phone>
            <selfCell
              title="文字"
              :column="true"
            >
              <el-input
                v-for="lItem in language"
                :key="lItem.code"
                v-model="configs_.first.desc[`${lItem.code=='HK'?'':lItem.code}textByPhone`]"
                :placeholder="`請輸入-${lItem.name}`"
                type="textarea"
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字大小">
              <selfSelectPX
                v-model="configs_.first.desc.fontSizeByPhone"
                @change="setValue('first')"
              ></selfSelectPX>
            </selfCell>
            <selfCell title="文字行高">
              <selfSelectPX
                v-model="configs_.first.desc.fontHeightByPhone"
                @change="setValue('first')"
              ></selfSelectPX>
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="configs_.first.desc.fontColorByPhone"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="投影顏色">
              <el-color-picker
                v-model="configs_.first.desc.shadowColorByPhone"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
          </template>
        </selfCheckPcOrPhone>

      </div>
      <!-- 小字 -->
      <div class="cell-b">
        <selfCheckPcOrPhone
          title="小字配置"
          :defalutActive="defalutActive"
        >
          <template #pc>
            <selfCell
              title="文字"
              :column="true"
            >
              <el-input
                v-for="lItem in language"
                :key="lItem.code"
                v-model="configs_.first.other[`${lItem.code=='HK'?'':lItem.code}text`]"
                :placeholder="`請輸入-${lItem.name}`"
                type="textarea"
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字大小">
              <selfSelectPX
                v-model="configs_.first.other.fontSize"
                @change="setValue('first')"
              ></selfSelectPX>
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="configs_.first.other.fontColor"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="線條顏色">
              <el-color-picker
                v-model="configs_.first.other.lineBgStart"
                show-alpha
                @change="setValue('first')"
              />
              <el-color-picker
                v-model="configs_.first.other.lineBgEnd"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
          </template>
          <template #phone>
            <selfCell
              title="文字"
              :column="true"
            >
              <el-input
                v-for="lItem in language"
                :key="lItem.code"
                v-model="configs_.first.other[`${lItem.code=='HK'?'':lItem.code}textByPhone`]"
                :placeholder="`請輸入-${lItem.name}`"
                type="textarea"
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字大小">
              <selfSelectPX
                v-model="configs_.first.other.fontSizeByPhone"
                @change="setValue('first')"
              ></selfSelectPX>
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="configs_.first.other.fontColorByPhone"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="線條顏色">
              <el-color-picker
                v-model="configs_.first.other.lineBgStartByPhone"
                show-alpha
                @change="setValue('first')"
              />
              <el-color-picker
                v-model="configs_.first.other.lineBgEndByPhone"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
          </template>
        </selfCheckPcOrPhone>

      </div>
      <!-- 圖片配置 -->
      <div class="cell-b">
        <selfCheckPcOrPhone
          title="圖片配置"
          :defalutActive="defalutActive"
        >
          <template #pc>
            <selfCell title="圖片地址">
              <selfUpload
                v-model="configs_.first.img.url"
                type="default"
                @change="setValue('first')"
                style="max-width:140px"
              />
            </selfCell>
            <selfCell title="陰影顏色">
              <el-color-picker
                v-model="configs_.first.img.shadowBg"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
          </template>
          <template #phone>
            <selfCell title="圖片地址">
              <selfUpload
                v-model="configs_.first.img.urlByPhone"
                type="default"
                @change="setValue('first')"
                style="max-width:140px"
              />
            </selfCell>
            <selfCell title="陰影顏色">
              <el-color-picker
                v-model="configs_.first.img.shadowBgByPhone"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
          </template>
        </selfCheckPcOrPhone>

      </div>
      <!-- 項目 -->
      <div class="cell-b">
        <selfCheckPcOrPhone
          title="項目配置"
          :defalutActive="defalutActive"
        >
          <template #pc>
            <selfCell title="背景色">
              <el-color-picker
                v-model="configs_.first.product.bg"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="邊框色">
              <el-color-picker
                v-model="configs_.first.product.borderColor"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字大小">
              <selfSelectPX
                v-model="configs_.first.product.fontSize"
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="configs_.first.product.fontColor"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <el-tabs
              type="border-card"
              editable
              @edit="handleTabsEditFirst"
            >
              <el-tab-pane
                v-for="item,index in configs_.first.product.childs"
                :key="index"
                :label="item.title || `第${index}項`"
                :name="index + ''"
              >
                <selfCell title="圖標">
                  <selfUpload
                    v-model="item.icon"
                    type="default"
                    @change="setValue('first')"
                    style="max-width:140px"
                  />
                </selfCell>
                <selfCell
                  title="文字"
                  :column="true"
                >
                  <el-input
                    v-for="lItem in language"
                    :key="lItem.code"
                    v-model="item[`${lItem.code=='HK'?'':lItem.code}title`]"
                    :placeholder="`請輸入-${lItem.name}`"
                    type="textarea"
                    @change="setValue('first')"
                  />
                </selfCell>
              </el-tab-pane>
            </el-tabs>
          </template>
          <template #phone>
            <selfCell title="背景色">
              <el-color-picker
                v-model="configs_.first.product.bgByPhone"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="邊框色">
              <el-color-picker
                v-model="configs_.first.product.borderColorByPhone"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字大小">
              <selfSelectPX
                v-model="configs_.first.product.fontSizeByPhone"
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="configs_.first.product.fontColorByPhone"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <el-tabs
              type="border-card"
              editable
              @edit="handleTabsEditFirst"
            >
              <el-tab-pane
                v-for="item,index in configs_.first.product.childs"
                :key="index"
                :label="item.titleByPhone || `第${index}項`"
                :name="index + ''"
              >
                <selfCell title="圖標">
                  <selfUpload
                    v-model="item.iconByPhone"
                    type="default"
                    @change="setValue('first')"
                    style="max-width:140px"
                  />
                </selfCell>
                <selfCell
                  title="文字"
                  :column="true"
                >
                  <el-input
                    v-for="lItem in language"
                    :key="lItem.code"
                    v-model="item[`${lItem.code=='HK'?'':lItem.code}titleByPhone`]"
                    :placeholder="`請輸入-${lItem.name}`"
                    type="textarea"
                    @change="setValue('first')"
                  />
                </selfCell>
              </el-tab-pane>
            </el-tabs>
          </template>
        </selfCheckPcOrPhone>

      </div>
      <!-- 按鈕 -->
      <div class="cell-b">
        <selfCheckPcOrPhone
          title="按鈕配置"
          :defalutActive="defalutActive"
        >
          <template #pc>
            <selfCell
              title="文字"
              :column="true"
            >
              <el-input
                v-for="lItem in language"
                :key="lItem.code"
                v-model="configs_.first.btn[`${lItem.code=='HK'?'':lItem.code}text`]"
                :placeholder="`請輸入-${lItem.name}`"
                type="textarea"
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字大小">
              <selfSelectPX
                v-model="configs_.first.btn.fontSize"
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="configs_.first.btn.fontColor"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="背景色">
              <el-color-picker
                v-model="configs_.first.btn.bgStart"
                show-alpha
                @change="setValue('first')"
              />
              <el-color-picker
                v-model="configs_.first.btn.bgEnd"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
          </template>
          <template #phone>
            <selfCell
              title="文字"
              :column="true"
            >
              <el-input
                v-for="lItem in language"
                :key="lItem.code"
                v-model="configs_.first.btn[`${lItem.code=='HK'?'':lItem.code}textByPhone`]"
                :placeholder="`請輸入-${lItem.name}`"
                type="textarea"
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字大小">
              <selfSelectPX
                v-model="configs_.first.btn.fontSizeByPhone"
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="configs_.first.btn.fontColorByPhone"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
            <selfCell title="背景色">
              <el-color-picker
                v-model="configs_.first.btn.bgStartByPhone"
                show-alpha
                @change="setValue('first')"
              />
              <el-color-picker
                v-model="configs_.first.btn.bgEndByPhone"
                show-alpha
                @change="setValue('first')"
              />
            </selfCell>
          </template>
        </selfCheckPcOrPhone>

      </div>
    </div>

    <div v-else-if="type == 'fc-success-dialog'">
      <div class="cell-b">
        <selfCheckPcOrPhone
          title="背景配置"
          :defalutActive="defalutActive"
        >
          <template #pc>
            <selfCell title="背景色">
              <el-color-picker
                v-model="configs_.third.bgColor"
                show-alpha
                @change="setValue('third')"
              />
            </selfCell>
          </template>
          <template #phone>
            <selfCell title="背景色">
              <el-color-picker
                v-model="configs_.third.bgColorByPhone"
                show-alpha
                @change="setValue('third')"
              />
            </selfCell>
          </template>
        </selfCheckPcOrPhone>
      </div>
      <div class="cell-b">
        <selfCheckPcOrPhone
          title="頂部配置"
          :defalutActive="defalutActive"
        >
          <template #pc>
            <selfCell title="背景圖片">
              <selfUpload
                v-model="configs_.third.headBg"
                type="default"
                @change="setValue('third')"
                style="max-height:100px"
              />
            </selfCell>
            <selfCell title="圖標">
              <selfUpload
                v-model="configs_.third.headIcon"
                type="default"
                @change="setValue('third')"
                style="max-width:140px"
              />
            </selfCell>
          </template>
          <template #phone>
            <selfCell title="背景圖片">
              <selfUpload
                v-model="configs_.third.headBgByPhone"
                type="default"
                @change="setValue('third')"
                style="max-height:100px"
              />
            </selfCell>
            <selfCell title="圖標">
              <selfUpload
                v-model="configs_.third.headIconByPhone"
                type="default"
                @change="setValue('third')"
                style="max-width:140px"
              />
            </selfCell>
          </template>
        </selfCheckPcOrPhone>
      </div>
      <div class="cell-b">
        <selfCheckPcOrPhone
          title="標題設置"
          :defalutActive="defalutActive"
        >
          <template #pc>
            <selfCell
              title="文字"
              :column="true"
            >
              <el-input
                v-for="lItem in language"
                :key="lItem.code"
                v-model="configs_.third[`${lItem.code=='HK'?'':lItem.code}title`]"
                :placeholder="`請輸入-${lItem.name}`"
                type="textarea"
                @change="setValue('third')"
              />
            </selfCell>
            <selfCell title="文字大小">
              <selfSelectPX
                v-model="configs_.third.titleFontSize"
                @change="setValue('third')"
              ></selfSelectPX>
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="configs_.third.titleFontColor"
                show-alpha
                @change="setValue('third')"
              />
            </selfCell>
          </template>
          <template #phone>
            <selfCell
              title="文字"
              :column="true"
            >
              <el-input
                v-for="lItem in language"
                :key="lItem.code"
                v-model="configs_.third[`${lItem.code=='HK'?'':lItem.code}titleByPhone`]"
                :placeholder="`請輸入-${lItem.name}`"
                type="textarea"
                @change="setValue('third')"
              />
            </selfCell>
            <selfCell title="文字大小">
              <selfSelectPX
                v-model="configs_.third.titleFontSizeByPhone"
                @change="setValue('third')"
              ></selfSelectPX>
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="configs_.third.titleFontColorByPhone"
                show-alpha
                @change="setValue('third')"
              />
            </selfCell>
          </template>
        </selfCheckPcOrPhone>
      </div>

      <div class="cell-b">
        <selfCheckPcOrPhone
          title="描述設置"
          :defalutActive="defalutActive"
        >
          <template #pc>
            <selfCell
              title="文字"
              :column="true"
            >
              <el-input
                v-for="lItem in language"
                :key="lItem.code"
                v-model="configs_.third[`${lItem.code=='HK'?'':lItem.code}desc`]"
                :placeholder="`請輸入-${lItem.name}`"
                type="textarea"
                @change="setValue('third')"
              />
            </selfCell>
            <selfCell title="文字大小">
              <selfSelectPX
                v-model="configs_.third.descFontSize"
                @change="setValue('third')"
              ></selfSelectPX>
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="configs_.third.descFontColor"
                show-alpha
                @change="setValue('third')"
              />
            </selfCell>
          </template>
          <template #phone>
            <selfCell
              title="文字"
              :column="true"
            >
              <el-input
                v-for="lItem in language"
                :key="lItem.code"
                v-model="configs_.third[`${lItem.code=='HK'?'':lItem.code}descByPhone`]"
                :placeholder="`請輸入-${lItem.name}`"
                type="textarea"
                @change="setValue('third')"
              />
            </selfCell>
            <selfCell title="文字大小">
              <selfSelectPX
                v-model="configs_.third.descFontSizeByPhone"
                @change="setValue('third')"
              ></selfSelectPX>
            </selfCell>
            <selfCell title="文字顏色">
              <el-color-picker
                v-model="configs_.third.descFontColorByPhone"
                show-alpha
                @change="setValue('third')"
              />
            </selfCell>
          </template>
        </selfCheckPcOrPhone>
      </div>

      <div class="cell-b">
        <selfCheckPcOrPhone
          title="按鈕設置"
          :defalutActive="defalutActive"
        >
          <template #pc>
            <el-tabs
              type="border-card"
              editable
              @edit="handleTabsEditThird"
            >
              <el-tab-pane
                v-for="item,index in configs_.third.btn"
                :key="index"
                :label="`第${index}項`"
                :name="index + ''"
              >
                <selfCell title="二維碼">
                  <selfUpload
                    v-model="item.qrcode"
                    type="default"
                    @change="setValue('third')"
                    style="max-width:140px"
                  />
                </selfCell>
                <selfCell title="圖標">
                  <selfUpload
                    v-model="item.icon"
                    type="default"
                    @change="setValue('third')"
                    style="max-width:140px"
                  />
                </selfCell>
                <selfCell title="跳轉">
                  <selfPagePicker
                    v-model="item.go"
                    @onChange="setValue('third')"
                  />
                </selfCell>
              </el-tab-pane>
            </el-tabs>
          </template>
        </selfCheckPcOrPhone>
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
import { getByName } from '@/js/utils/default_config';
export default {
  name: "edit-full-screen-ad",
  mixins: [langEditMixin],
  props: {
    type: {
      default () {
        return 'title';
      },
    },
    configs: {
      default () {
        return {}
      },
    },
    index: {
      default () {
        return 0;
      },
    },
  },
  data () {
    return {
      configs_: this.$util.object.deepClone(getByName('fullScreenAd').configs),
    }
  },
  watch: {
    configs: {
      deep: true,
      handler (val) {
        this.configs_ = this.$util.object.deepClone(val);
      }
    }
  },
  computed: {
    defalutActive () {
      let W = this.$util.window.get();
      if (W.t == 1) {
        return 'phone'
      } else {
        return 'pc'
      }
    }
  },
  created () {
    this.configs_ = this.$util.object.deepClone(this.configs);
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs_[key] })
    },
    addItem () {
      let setting = getByName('fullScreenAd')
      let item = setting.configs.first.product.childs[0]
      this.configs_.first.product.childs.push(this.$util.object.deepClone(item))
      this.setValue('first')
    },
    removeItem (index) {
      this.configs_.first.product.childs.splice(index, 1)
      this.setValue('first')
    },

    handleTabsEditFirst (targetName, action) {
      if (action === 'add') {
        this.addItem();
      } else if (action === 'remove') {
        this.removeItem(targetName);
      }
    },

    addItemThird () {
      let setting = getByName('fullScreenAd')
      let item = setting.configs.third.btn[0]
      this.configs_.third.btn.push(this.$util.object.deepClone(item))
      this.setValue('third')
    },
    removeItemThird (index) {
      this.configs_.third.btn.splice(index, 1)
      this.setValue('third')
    },

    handleTabsEditThird (targetName, action) {
      if (action === 'add') {
        this.addItemThird();
      } else if (action === 'remove') {
        this.removeItemThird(targetName);
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>